import Vue from 'vue'
import VueRouter from 'vue-router'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.use(VueRouter)

const routes: Array<any> = []

const router = new VueRouter({
  'mode': 'history',
  'base': process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if(to.hash) {
          resolve({
            selector: to.hash,
          })
        } else {
          resolve({
            x: 0,
            y: 0,
          })
        }
      }, 600)
    })
  },
})

Sentry.init({
  Vue,
  dsn             : "https://956c58894df44cd18a799bc537748a65@cabinet.presscode.info/clientErrorHandler/4",
  integrations    : [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 20.0,
});

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push
;(VueRouter as any).prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return (originalPush as any).call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.cancelled) || VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)) {
      return err
    }

    return Promise.reject(err)
  })
}

export default router
